const moment = require('moment');

// this can be pretty dynamic, use lodash.get
module.exports.fieldExists = (params, almanac) => {
  return almanac
    .factValue(params.key)
    .then(queryObj => {
      return !!queryObj[params.field];
    })
    .catch(error => error.code !== 'UNDEFINED_FACT' && console.log(error));
};

// this can be pretty dynamic, use lodash.get
module.exports.getByKeyField = (params, almanac) => {
  return almanac
    .factValue(params.key)
    .then(queryObj => {
      if (queryObj && queryObj[params.field]) {
        if (params.simplifyArray) {
          if (queryObj[params.field] == 0) {
            return null; // i.e. don't return [] to the rules almanac
          }
          return queryObj[params.field].map(elem => elem[params.simplifyArray]);
        }
        if (Array.isArray(queryObj[params.field]) && queryObj[params.field].length === 0)
          return null;
        return queryObj[params.field];
      }
      return null;
    })
    .catch(error => error.code !== 'UNDEFINED_FACT' && console.log(error));
};

module.exports.comorbiditySubset = (params, almanac) => {
  return almanac
    .factValue('diagnosis', {}, '.comorbidities')
    .then(queryObj => {
      if (queryObj && params.values) {
        return queryObj.some(val => params.values.indexOf(val) >= 0);
      }
      return false;
    })
    .catch(err => console.log('Swallowing fact error', err));
};

module.exports.daysSinceSurgery = (params, almanac) => {
  return almanac
    .factValue('diagnosis')
    .then(diagnosis => {
      if (diagnosis && diagnosis.surgery_date) {
        const today = moment();
        const surgery = moment(diagnosis.surgery_date);
        const diff = today.diff(surgery, 'days');
        return diff;
      }
      return 0;
    })
    .catch(err => console.log('Swallowing fact error', err));
};

module.exports.daysSinceInjury = (params, almanac) => {
  return almanac
    .factValue('diagnosis')
    .then(diagnosis => {
      if (diagnosis && diagnosis.injury_date) {
        const injury = moment(diagnosis.injury_date);
        const today = moment();
        const diff = today.diff(injury, 'days');
        return diff;
      }
      return 0;
    })
    .catch(err => console.log('Swallowing fact error', err));
};

module.exports.xraySinceInjury = (params, almanac) => {
  return almanac
    .factValue('diagnosis')
    .then(diagnosis => {
      if (diagnosis && diagnosis.injury_date) {
        const injury = moment(diagnosis.injury_date);
        if (diagnosis.last_xray_date) {
          const lastxray = moment(diagnosis.last_xray_date);
          const diff = lastxray.diff(injury, 'days');
          return diff;
        }
        return null;
      }
      return null;
    })
    .catch(err => console.log('Swallowing fact error', err));
};

module.exports.xraySinceRx = (params, almanac) => {
  return almanac
    .factValue('diagnosis')
    .then(diagnosis => {
      if (diagnosis && diagnosis.rx_date) {
        const rx = moment(diagnosis.rx_date);
        if (diagnosis.last_xray_date) {
          const lastxray = moment(diagnosis.last_xray_date);
          const diff = lastxray.diff(rx, 'days');
          return diff;
        }
        return null;
      }
      return null;
    })
    .catch(err => console.log('Swallowing fact error', err));
};

module.exports.timeBetweenXrays = (params, almanac) => {
  return almanac
    .factValue('diagnosis')
    .then(diagnosis => {
      if (diagnosis && diagnosis.first_xray_date) {
        const firstxray = moment(diagnosis.first_xray_date);
        if (diagnosis.last_xray_date) {
          const lastxray = moment(diagnosis.last_xray_date);
          const diff = lastxray.diff(firstxray, 'days');
          return diff;
        }
        return null;
      }
      return null;
    })
    .catch(err => console.log('Swallowing fact error', err));
};

module.exports.medicareIcd = (params, almanac) => {
  return almanac
    .factValue('diagnosis')
    .then(diagnosis => {
      const validCodes = ['S', 'K', 'A', 'B', 'C'];
      if (diagnosis) {
        if (diagnosis.icd10_0) {
          const icd10_0 = diagnosis.icd10_0;
          const last_char0 = icd10_0.slice(-1).toUpperCase();

          if (validCodes?.includes(last_char0)) {
            return true;
          }
        }
        if (diagnosis.icd10_1) {
          const last_char1 = diagnosis.icd10_1.slice(-1).toUpperCase();
          if (validCodes?.includes(last_char1)) {
            return true;
          }
        }
        if (diagnosis.icd10_2) {
          const last_char2 = diagnosis.icd10_2.slice(-1).toUpperCase();
          if (validCodes?.includes(last_char2)) {
            return true;
          }
        }
        if (diagnosis.icd10_3) {
          console.log(diagnosis.icd10_3);
          const last_char3 = diagnosis.icd10_3.slice(-1).toUpperCase();
          if (validCodes?.includes(last_char3)) {
            return true;
          }
        }

        return false;
      }

      return false;
    })
    .catch(err => console.log('Swallowing fact error', err));
};
