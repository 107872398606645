import superagent from 'superagent';
import moment from 'moment';
import { push } from 'connected-react-router';
import { showToast } from 'shared/utils/toast';
import { hasRequiredDocs } from 'shared/utils/orders';
import { generateUniqueId } from 'shared/utils/uuid';
import { SubmitTypes } from 'shared/utils/constants';
import config from 'exogen/config';
import apiClient from 'exogen/utils/api-client';
import * as OrderActions from '../constants/order-actions';
import { getSystemConfig } from '../actions/form-data';
import { getUnlinkedDocuments } from '../reducers/documents';
import { getNotifications } from '../reducers/notifications';
import * as Sentry from '@sentry/browser';
import _get from 'lodash.get';
import _set from 'lodash.set';
import _pickBy from 'lodash.pickby';

import { emptyOrder } from 'shared/utils/orders';
import * as ReminderActions from 'exogen/redux/reducers/reminders';
import { Groups } from 'shared/utils/constants';
import errorMessage from 'shared/components/form/fields/error-message';

const captureForSentry = (details, message) => {
  if (__SENTRY__) {
    Sentry.configureScope(scope => {
      scope.setExtra('details', details);
    });
    Sentry.captureMessage(message);
  }
};

const timeout = {
  response: 120000,
  deadline: 120000
};

const isOnline = getState => getState().network.status.toLowerCase() === 'online';

export function setReasonsList(reasonsArr) {
  return dispatch => {
    dispatch({
      type: OrderActions.SET_REASONS_LIST,
      reasonsArr: reasonsArr
    });
  };
}

export function updateOrder(id, values) {
  return {
    type: OrderActions.UPDATE_ORDER,
    id,
    values
  };
  f;
}

export function setCurrentOrder(currentOrderId, orderLocation) {
  const orderId = (currentOrderId + '').startsWith('tmp')
    ? currentOrderId
    : parseInt(currentOrderId, 10);

  return dispatch => {
    dispatch({
      type: OrderActions.SET_CURRENT_ORDER,
      currentOrderId: orderId,
      orderLocation
    });

    try {
      dispatch(getOmOrder(currentOrderId, orderLocation));
    } catch (e) {
      console.log(e);
    }
  };
}

export function setDraftAsOrder(draftId) {
  return {
    type: OrderActions.SET_DRAFT_AS_ORDER,
    draftId
  };
}

export function openGuideline(guidelineId) {
  return dispatch => {
    dispatch(push(`/guideline/${guidelineId}`));
  };
}

export function getOrder(orderId, orderLocation) {
  return {
    types: [OrderActions.ORDER_GET, OrderActions.ORDER_GET_SUCCESS, OrderActions.ORDER_GET_FAIL],
    orderId,
    orderLocation,
    promise: client => client.get(`orders/${orderId}/`)
  };
}

export function getDrafts() {
  return {
    types: [OrderActions.DRAFTS_ALL, OrderActions.DRAFTS_ALL_SUCCESS, OrderActions.DRAFTS_ALL_FAIL],
    promise: client => client.get(`drafts/`)
  };
}

export function getDraft(orderId, orderLocation) {
  return {
    types: [OrderActions.ORDER_GET, OrderActions.ORDER_GET_SUCCESS, OrderActions.ORDER_GET_FAIL],
    orderId,
    orderLocation,
    promise: client => client.get(`drafts/${orderId}/`)
  };
}

export function updateShipping(orderId, values) {
  return {
    types: [
      OrderActions.ORDERS_SHIPPING,
      OrderActions.ORDERS_SHIPPING_SUCCESS,
      OrderActions.ORDERS_SHIPPING_FAIL
    ],
    orderId,
    values,
    promise: client => client.put(`orders/${orderId}/shipping_address/`, { params: values })
  };
}

export function sendShippingFax({ orderId = null, faxOverrideNumber = null, updateFaxNumberOnly = false }) {
  return async dispatch => {
    try {
      dispatch({ type: OrderActions.ORDERS_SHIPPING_FAX, orderId });
      const result = await apiClient.post(`orders/${orderId}/send_shipped_fax/`, { params: { faxOverrideNumber, updateFaxNumberOnly } });
      dispatch({ type: OrderActions.ORDERS_SHIPPING_SUCCESS_FAX, orderId });
      showToast({
        type: 'info',
        message: updateFaxNumberOnly ? 'Fax updated.' : 'Shipping fax sent to physician.'
      });
      dispatch(getOrder(orderId, 'orders'));
    } catch (error) {
      console.log(error);
      showToast({
        type: 'error',
        message: 'Error with shipping fax.'
      });
      dispatch({ type: OrderActions.ORDERS_SHIPPING_FAIL_FAX, orderId, error });
    }
  };
}

export function updateShippingModal(show) {
  return {
    type: OrderActions.ORDERS_SHIPPING_MODAL,
    show
  };
}

export function getOmOrder(orderId, orderLocation) {
  return async dispatch => {
    try {
      dispatch({
        type: OrderActions.ORDER_GET,
        orderId,
        orderLocation
      });
      const res = await apiClient.get(
        `${orderLocation === 'drafts' ? 'drafts' : 'orders'}/${orderId}/`
      );

      if (!res?.body?.order && (orderId + '').startsWith('tmp')) {
        dispatch({
          type: OrderActions.ORDER_GET_FAIL,
          orderId,
          orderLocation,
          error: 'Order does not exist, creating new order'
        });
        dispatch(createNewOrder(orderId));
      } else {
        dispatch({
          type: OrderActions.ORDER_GET_SUCCESS,
          orderId,
          orderLocation,
          result: res?.body
        });
      }
    } catch (response) {
      const message =
        response && response.body && response.body.error
          ? response.body.error
          : 'Error adding notes';

      dispatch({
        type: OrderActions.ORDER_GET_FAIL,
        orderId,
        orderLocation,
        error: message
      });
      dispatch(push('/'));
    }
  };
}

export function clearCurrentOrder() {
  return { type: OrderActions.ORDER_CLEAR_CURRENT };
}

export function openOrder(orderId, orderLocation, scroll = null) {
  return dispatch => {
    dispatch(setCurrentOrder(orderId, orderLocation));
    const url = scroll
      ? `/order/${orderId}/${orderLocation}?scroll=${scroll}`
      : `/order/${orderId}/${orderLocation}`;
    dispatch(push(url));
  };
}

export function requestFitting(orderId) {
  return {
    types: [
      OrderActions.REQUEST_LIVE_FITTING,
      OrderActions.REQUEST_LIVE_FITTING_SUCCESS,
      OrderActions.REQUEST_LIVE_FITTING_FAIL
    ],
    orderId,
    promise: client => client.post(`orders/${orderId}/live-fitting-request/`)
  };
}

export function cancelFitting(orderId) {
  return {
    types: [
      OrderActions.CANCEL_LIVE_FITTING,
      OrderActions.CANCEL_LIVE_FITTING_SUCCESS,
      OrderActions.CANCEL_LIVE_FITTING_FAIL
    ],
    orderId,
    promise: client => client.post(`orders/${orderId}/cancel-live-fitting-request/`)
  };
}

export function requestFittingDraft(draftId) {
  return {
    types: [
      OrderActions.REQUEST_DRAFT_LIVE_FITTING,
      OrderActions.REQUEST_DRAFT_LIVE_FITTING_SUCCESS,
      OrderActions.REQUEST_DRAFT_LIVE_FITTING_FAIL
    ],
    draftId,
    promise: client => client.post(`drafts/${draftId}/live-fitting-request/`)
  };
}

export function cancelFittingDraft(draftId) {
  return {
    types: [
      OrderActions.CANCEL_DRAFT_LIVE_FITTING,
      OrderActions.CANCEL_DRAFT_LIVE_FITTING_SUCCESS,
      OrderActions.CANCEL_DRAFT_LIVE_FITTING_FAIL
    ],
    draftId,
    promise: client => client.post(`drafts/${draftId}/cancel-live-fitting-request/`)
  };
}

export function requestHelp({ orderId, reason, note, rep = null }) {
  return {
    types: [
      OrderActions.REQUEST_HELP,
      OrderActions.REQUEST_HELP_SUCCESS,
      OrderActions.REQUEST_HELP_FAIL
    ],
    orderId,
    reason,
    promise: client =>
      rep
        ? client.post(`drafts/${orderId}/help/`, { params: { rep, reason, note } })
        : client.post(`orders/${orderId}/help/`, { params: { reason, note } })
  };
}

export function updateInitialPatientContact({ orderId, IPCText }) {
  return async dispatch => {
    try {
      dispatch({ type: OrderActions.UPDATE_IPC_TEXT, orderId, IPCText });
      const result = await apiClient.put(`orders/${orderId}/initial_patient_contact/`, {
        params: { initial_patient_contact: IPCText }
      });
      dispatch({ type: OrderActions.UPDATE_IPC_TEXT_SUCCESS, orderId, IPCText });
      showToast({
        type: 'info',
        message: 'Note created, order reloading to get the latest notes...'
      });
      dispatch(getOrder(orderId, 'orders'));
    } catch (error) {
      dispatch({ type: OrderActions.UPDATE_IPC_TEXT_FAIL, orderId, IPCText, error });
    }
  };
}

export function createOrderNote({ orderId, note, type, isOm = false, cb = null, recipient }) {
  return async dispatch => {
    try {
      dispatch({ type: OrderActions.ORDER_NOTES, orderId, isOm });
      const res = await apiClient.post(`orders/${orderId}/notes/`, {
        params: { note, type, isOm, recipient }
      });

      dispatch({
        type: OrderActions.ORDER_NOTES_SUCCESS,
        orderId,
        isOm,
        result: res?.body
      });

      if (cb) cb();
    } catch (response) {
      const message =
        response && response.body && response.body.error
          ? response.body.error
          : 'Error adding notes';
      dispatch({ type: OrderActions.ORDER_NOTES_FAIL, orderId, isOm, error: message });
    }
  };
}

export function createDraftNote({
  draftId,
  note,
  type,
  isOm = false,
  cb = null,
  formValues,
  ignoreSave = false,
  recipient
}) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: OrderActions.ORDER_NOTES, draftId, isOm });
      const orderState = getState().orders;
      const draft = { ...orderState.currentOrder, order: formValues };
      if (!ignoreSave) {
        const promise =
          draft && draft.hasOwnProperty('id')
            ? apiClient.put(`drafts/${draft.id}/`, { params: draft })
            : apiClient.post('drafts/', { params: draft });

        try {
          const response = await promise;

          dispatch({
            type: OrderActions.DRAFT_SUBMIT_SUCCESS,
            draft: response.body.draft
          });
        } catch ({ status, body = {}, header }) {
          if (status === 404) {
            showToast({
              type: 'error',
              message: 'Draft was not found.  Deleting and redirecting to dashboard.'
            });
            dispatch({ type: OrderActions.DRAFT_DELETE, id: updatedDraft.client_id });
            dispatch(push('/'));
          }
          if (
            status === 401 &&
            header.hasOwnProperty('user-change') &&
            header['user-change'] === 'true'
          ) {
            window.location.replace('/exogen-direct/app');
          } else {
            const err = body && body.error ? body.error : null;
            const message = err
              ? `Error saving draft to server: ${err}`
              : 'Error saving draft to server';

            showToast({ type: 'error', message });

            if (err === 'Draft is outdated.  Please download changes.') {
              dispatch(getDraft(updatedDraft.id));
            }

            dispatch({ type: OrderActions.ORDER_NOTES_FAIL, draftId, isOm, error: message });
          }
        }
      }

      const res = await apiClient.post(`drafts/${draftId}/notes/`, {
        params: { note, type, isOm, recipient }
      });

      dispatch({
        type: OrderActions.ORDER_NOTES_SUCCESS,
        draftId,
        isOm,
        result: res?.body
      });

      if (cb) cb();
    } catch (response) {
      const message =
        response && response.body && response.body.error
          ? response.body.error
          : 'Error adding notes';
      dispatch({ type: OrderActions.ORDER_NOTES_FAIL, draftId, isOm, error: message });
    }
  };
}

export function deleteMa(medicalAssistant, medicalAssistants, currentOrderId, orderLocation) {
  return {
    types: [OrderActions.MA_DELETE, OrderActions.MA_DELETE_SUCCESS, OrderActions.MA_DELETE_FAIL],
    currentOrderId,
    medicalAssistant,
    medicalAssistants,
    orderLocation,
    promise: client => client.delete(`medical-assistants/${medicalAssistant.id}/`)
  };
}

export function openOrderFulfillment(orderId, orderLocation) {
  return dispatch => {
    dispatch(setCurrentOrder(orderId, orderLocation));
    dispatch(push(`/order/${orderId}/${orderLocation}/fulfillment`));
  };
}

export function convertGuideline(guideline, formValues) {
  const convGuideline = () => {
    return {
      type: OrderActions.GUIDELINE_CONVERT,
      guideline,
      formValues
    };
  };

  return dispatch => {
    dispatch(convGuideline(guideline));
    dispatch(openOrder(formValues.id, 'drafts'));
  };
}

function createNewOrder(id) {
  const emptyOrd = emptyOrder();
  emptyOrd.order.id = id;
  emptyOrd.client_id = id;
  return (dispatch, getState) => {
    dispatch({
      type: OrderActions.NEW_ORDER,
      emptyOrd
    });
  };
}

function createNewGuideline(id) {
  const emptyGuideline = emptyOrder();

  emptyGuideline.order.id = id;
  emptyGuideline.client_id = id;
  return {
    type: OrderActions.NEW_GUIDELINE,
    emptyGuideline
  };
}

export function saveOmChanges(formValues) {
  return async (dispatch, getState) => {
    let ordersState = getState().orders;
    const userState = getState().user;

    if (!ordersState.currentOrder) {
      alert("Can't save, returning to dashboard.");
      dispatch(push('/'));
      return;
    }

    dispatch({
      type: OrderActions.ORDER_SAVE_CHANGES,
      formValues
    });

    // dispatch({ type: OrderActions.DRAFT_SAVING_FALSE });

    showToast({ type: 'info', message: 'Order saved...' });

    ordersState = getState().orders;

    const updatedDraft = ordersState.currentOrder;

    if (!updatedDraft) {
      alert("Can't find draft, returning to dashboard.");
      dispatch({ type: OrderActions.DRAFT_SAVING_FALSE });
      dispatch(push('/'));
      return;
    }

    if (!String(updatedDraft.order.id).startsWith('tmp')) {
      alert('Invalid draft id.  Returning to dashboard.');
      dispatch({ type: OrderActions.DRAFT_SAVING_FALSE });
      dispatch(push('/'));
      return;
    }

    if (updatedDraft.order.id !== formValues.id || updatedDraft.client_id !== formValues.id) {
      captureForSentry({ updatedDraft, formValues }, 'Draft id does not match form id');
      alert('Invalid draft form.  Returning to dashboard');
      dispatch({ type: OrderActions.DRAFT_SAVING_FALSE });
      dispatch(push('/'));
      return;
    }

    if (updatedDraft.order.sap_id || formValues.sap_id) {
      captureForSentry({ updatedDraft, formValues }, 'Draft id does not match form id');
      alert('Invalid draft form.  Returning to dashboard');
      dispatch({ type: OrderActions.DRAFT_SAVING_FALSE });
      dispatch(push('/'));
      return;
    }

    const promise =
      updatedDraft && updatedDraft.hasOwnProperty('id')
        ? apiClient.put(`drafts/${updatedDraft.id}/`, { params: updatedDraft })
        : apiClient.post('drafts/', { params: updatedDraft });

    try {
      const response = await promise;
      dispatch({
        type: OrderActions.DRAFT_SUBMIT_SUCCESS,
        draft: response.body.draft
      });
      dispatch({ type: OrderActions.DRAFT_SAVING_FALSE });
    } catch ({ status, body = {}, header }) {
      if (status === 404) {
        showToast({
          type: 'error',
          message: 'Draft was not found.  Deleting and redirecting to dashboard.'
        });
        dispatch({ type: OrderActions.DRAFT_DELETE, id: updatedDraft.client_id });
        dispatch({ type: OrderActions.DRAFT_SAVING_FALSE });
        dispatch(push('/'));
      }
      if (
        status === 401 &&
        header.hasOwnProperty('user-change') &&
        header['user-change'] === 'true'
      ) {
        dispatch({ type: OrderActions.DRAFT_SAVING_FALSE });
        window.location.replace('/exogen-direct/app');
      } else {
        const err = body && body.error ? body.error : null;
        const message = err
          ? `Error saving draft to server: ${err}`
          : 'Error saving draft to server';

        showToast({ type: 'error', message });

        if (err === 'Draft is outdated.  Please download changes.') {
          dispatch(getDraft(updatedDraft.id));
        }
        dispatch({ type: OrderActions.DRAFT_SAVING_FALSE });
      }
    }
  };
}

export function saveChanges(formValues) {
  return async (dispatch, getState) => {
    let ordersState = getState().orders;

    if (!ordersState.currentOrderId || !formValues.id) {
      alert("Can't save, returning to dashboard.");
      dispatch(push('/'));
      return;
    }

    dispatch({
      type: OrderActions.ORDER_SAVE_CHANGES,
      formValues
    });

    showToast({ type: 'info', message: 'Order saved...' });

    if (isOnline(getState)) {
      ordersState = getState().orders;

      const updatedDraft = ordersState.drafts.items.find(
        draft => draft.client_id === ordersState.currentOrderId
      );

      if (!updatedDraft) {
        alert("Can't find draft, returning to dashboard.");
        dispatch(push('/'));
        return;
      }

      if (!String(updatedDraft.order.id).startsWith('tmp')) {
        alert('Invalid draft id.  Returning to dashboard.');
        dispatch(push('/'));
        return;
      }

      if (updatedDraft.order.id !== formValues.id || updatedDraft.client_id !== formValues.id) {
        captureForSentry({ updatedDraft, formValues }, 'Draft id does not match form id');
        alert('Invalid draft form.  Returning to dashboard');
        dispatch(push('/'));
        return;
      }

      if (updatedDraft.order.sap_id || formValues.sap_id) {
        captureForSentry({ updatedDraft, formValues }, 'Draft id does not match form id');
        alert('Invalid draft form.  Returning to dashboard');
        dispatch(push('/'));
        return;
      }

      const promise =
        updatedDraft && updatedDraft.hasOwnProperty('id')
          ? apiClient.put(`drafts/${updatedDraft.id}/`, { params: updatedDraft })
          : apiClient.post('drafts/', { params: updatedDraft });

      try {
        const response = await promise;
        dispatch({
          type: OrderActions.DRAFT_SUBMIT_SUCCESS,
          draft: response.body.draft
        });
      } catch ({ status, body = {}, header }) {
        if (status === 404) {
          showToast({
            type: 'error',
            message: 'Draft was not found.  Deleting and redirecting to dashboard.'
          });
          dispatch({ type: OrderActions.DRAFT_DELETE, id: updatedDraft.client_id });
          dispatch(push('/'));
        }
        if (
          status === 401 &&
          header.hasOwnProperty('user-change') &&
          header['user-change'] === 'true'
        ) {
          window.location.replace('/exogen-direct/app');
        } else {
          const err = body && body.error ? body.error : null;

          const message = err
            ? `Error saving draft to server: ${err}`
            : 'Error saving draft to server';

          showToast({ type: 'error', message });

          if (err === 'Draft is outdated.  Please download changes.') {
            dispatch(getDraft(updatedDraft.id));
          }
        }
      }
    } else {
      showToast({
        type: 'warn',
        message: 'You are offline.  Changes were not saved to the server.'
      });
    }
  };
}

export function toggleSecondPayer() {
  return {
    type: OrderActions.TOGGLE_SECOND_PAYER
  };
}

export function toggleShowMore() {
  return {
    type: OrderActions.TOGGLE_SHOW_MORE
  };
}

export function newOrder() {
  return (dispatch, getState) => {
    const id = generateUniqueId();
    const drafts = getState().orders.drafts;
    const existingIndex = drafts.items.findIndex(draft => draft.client_id === id);

    if (existingIndex === -1) {
      dispatch(createNewOrder(id));
      dispatch(push(`/order/${id}/drafts`));
    } else {
      alert(`Draft exists with id ${id}.  Returning to dashboard.`);
      dispatch(push('/'));
    }
  };
}

export function newGuideline() {
  return (dispatch, getState) => {
    const id = generateUniqueId();
    const drafts = getState().orders.drafts;
    const existingIndex = drafts.items.findIndex(draft => draft.client_id === id);

    if (existingIndex === -1) {
      dispatch(createNewGuideline(id));
      dispatch(push(`/guideline/${id}`));
    } else {
      alert(`Draft exists with id ${id}.  Returning to dashboard.`);
      dispatch(push('/'));
    }
  };
}

export function getInventory() {
  return {
    types: [
      OrderActions.INVENTORY_ALL,
      OrderActions.INVENTORY_ALL_SUCCESS,
      OrderActions.INVENTORY_ALL_FAIL
    ],
    promise: client => client.get(`inventory/`)
  };
}

export function getMyOrders(user = null, closed = false, territory = 'all') {
  return {
    types: [OrderActions.ORDERS_ALL, OrderActions.ORDERS_ALL_SUCCESS, OrderActions.ORDERS_ALL_FAIL],
    user,
    closed,
    promise: client => client.get(`orders/?territory=${territory}${closed ? '&closed=true' : ''}`)
  };
}

export function clearOrders() {
  return {
    type: OrderActions.ORDERS_CLEAR
  };
}

// export function getMyOrderNotes(territory = 'all') {
//   return {
//     types: [
//       OrderActions.ORDERS_NOTES,
//       OrderActions.ORDERS_NOTES_SUCCESS,
//       OrderActions.ORDERS_NOTES_FAIL
//     ],
//     promise: client => client.get(`notes/?territory=${territory}`)
//   };
// }

export function getMyOrderDocusignDocs(territory = 'all') {
  return {
    types: [
      OrderActions.ORDERS_DOCUSIGN,
      OrderActions.ORDERS_DOCUSIGN_SUCCESS,
      OrderActions.ORDERS_DOCUSIGN_FAIL
    ],
    promise: client => client.get(`docusign/?territory=${territory}`)
  };
}

export function getTerritories() {
  return {
    types: [
      OrderActions.TERRITORIES,
      OrderActions.TERRITORIES_SUCCESS,
      OrderActions.TERRITORIES_FAIL
    ],
    promise: client => client.get(`user/territories/`)
  };
}

export function getStages() {
  return {
    types: [OrderActions.STAGES, OrderActions.STAGES_SUCCESS, OrderActions.STAGES_FAIL],
    promise: client => client.get(`order-stages`)
  };
}

export function refreshOrderData(territory = null) {
  return async (dispatch, getState) => {
    const { user } = getState().user;

    dispatch(getSystemConfig());
    dispatch(getNotifications());
    dispatch(getTerritories());
    dispatch(getStages());
    dispatch(ReminderActions.listReminders());

    dispatch({
      types: [
        OrderActions.DRAFTS_ALL,
        OrderActions.DRAFTS_ALL_SUCCESS,
        OrderActions.DRAFTS_ALL_FAIL
      ],
      user,
      promise: client => client.get(`drafts/`)
    });

    dispatch({
      types: [
        OrderActions.INVENTORY_ALL,
        OrderActions.INVENTORY_ALL_SUCCESS,
        OrderActions.INVENTORY_ALL_FAIL
      ],
      user,
      promise: client => client.get(`inventory/`)
    });

    dispatch(getUnlinkedDocuments());
  };
}

export function cancelOrder({ orderId, orderLocation, reason }) {
  return {
    types: [
      OrderActions.ORDER_CANCEL,
      OrderActions.ORDER_CANCEL_SUCCESS,
      OrderActions.ORDER_CANCEL_FAIL
    ],
    orderId,
    orderLocation,
    promise: client =>
      client.post(`orders/${orderId}/cancel/`, {
        params: {
          reason
        }
      })
  };
}

export function validateSerial(serial) {
  return async dispatch => {
    dispatch({ type: OrderActions.SERIAL_VALIDATION, serial });
    try {
      const response = await apiClient.get(`inventory/validate/?serial_no=${serial}`);
      dispatch({ type: OrderActions.SERIAL_VALIDATION_SUCCESS, item: response.body.data });
    } catch (error) {
      dispatch({ type: OrderActions.SERIAL_VALIDATION_FAIL });
    }
  };
}

export function resetSerialValidation() {
  return {
    type: OrderActions.SERIAL_VALIDATION_RESET
  };
}

const disableForm = () => {
  return { type: OrderActions.ORDER_SUBMIT };
};

const submitSuccess = (result, orderId) => {
  return {
    type: OrderActions.ORDER_SUBMIT_SUCCESS,
    result,
    orderId
  };
};

const submitFail = (error = {}, result) => {
  return {
    type: OrderActions.ORDER_SUBMIT_FAIL,
    error,
    result
  };
};

const formErrors = errors => {
  return {
    type: OrderActions.ORDER_SUBMIT_ERRORS,
    errors
  };
};

export const submitErrors = (errors, dispatch) => {
  dispatch(formErrors(errors));
};

export const submitFulfillment = fulfillment => {
  return dispatch => {
    const data = new FormData();
    const { pair_form, ...nonFileFields } = fulfillment;

    for (const key in nonFileFields) {
      if (nonFileFields.hasOwnProperty(key)) {
        const value =
          key === 'pair_signature_date'
            ? formatDate(nonFileFields[key], 'YYYY-MM-DD')
            : nonFileFields[key];
        data.append(key, value);
      }
    }

    if (pair_form) {
      data.append('pair_form', pair_form);
      data.append('pair_form_original', pair_form.name);
    }

    const { orderId } = fulfillment;

    dispatch({ type: OrderActions.FULFILLMENT_SUBMIT, orderId, fulfillment });

    superagent
      .post(`${config.apiSegment}/orders/${orderId}/fulfillment/`)
      .set('BIOVENTUS-USER-ID', localStorage.getItem('user_id'))
      .set('BIOVENTUS-CLIENT-ID', 'exogen-direct')
      .timeout(timeout)
      .send(data)
      .on('progress', e => {
        const percent = typeof e.percent === 'undefined' ? 100 : e.percent;
        dispatch({ type: OrderActions.FULFILLMENT_SUBMIT_PROGRESS, progress: percent });
      })
      .end((err, response) => {
        if (!err && response.body && response.body.data) {
          dispatch({ type: OrderActions.FULFILLMENT_SUBMIT_SUCCESS });
          dispatch(push('/'));
        } else {
          if (
            err &&
            err.status === 401 &&
            err.header &&
            err.header.hasOwnProperty('user-change') &&
            err.header['user-change'] === 'true'
          ) {
            window.location.replace('/exogen-direct/app');
          }
          if (err && !response) {
            alert('Error submitting fulfillment: ' + err);
            dispatch({ type: OrderActions.FULFILLMENT_SUBMIT_FAIL, error: err });
          } else {
            const error =
              response && response.body && response.body.error
                ? response.body.error
                : 'Error submitting fulfillment';
            alert('Error submitting fulfillment: ' + error);
            dispatch({ type: OrderActions.FULFILLMENT_SUBMIT_FAIL, error: error });
          }
        }
      });
  };
};

export const formatDate = (val, format) =>
  val
    ? moment
      .utc(val)
      .format(format)
      .toString()
    : null;

const dateNormalizeFields = [
  'diagnosis.surgery_date',
  'diagnosis.rx_date',
  'diagnosis.first_xray_date',
  'diagnosis.last_xray_date',
  'patient.date_of_birth',
  'pair_signature_date',
  'diagnosis.injury_date',
  'follow_up',
  'insured_if_other_dob'
];

export const normalizeOrderDates = order =>
  dateNormalizeFields.reduce((acc, elem) => {
    _set(acc, elem, formatDate(_get(acc, elem, null), 'YYYY-MM-DD'));
    return acc;
  }, order);

export const normalizeOrderDatesForForm = order =>
  dateNormalizeFields.reduce((acc, elem) => {
    _set(acc, elem, formatDate(_get(acc, elem, null), 'M/DD/YYYY'));
    return acc;
  }, order);

export const submitOrder = draft => {
  return async (dispatch, getState) => {
    const state = getState().orders;

    if (!draft || !draft.id) {
      alert('Invalid order');
      return null;
    }

    if (!String(draft.id).startsWith('tmp')) {
      alert('Order Previously Submitted');
      return null;
    }

    dispatch(saveOmChanges(draft));

    const outerDraft = state.currentOrder;

    const missingRequiredDocs =
      state.requiredDocIds && state.requiredDocIds.length > 0
        ? !hasRequiredDocs(state.requiredDocIds, outerDraft.documents)
        : false;

    if (missingRequiredDocs) {
      dispatch(submitFail(null, { has_required_docs: false }));
      return null;
    }

    let submittedOrder = {
      ...draft,
      client_id: draft.id,
      id: null,
      documents: outerDraft.documents,
      order_notes: draft.order_notes
        .filter(elem => elem)
        .map(elem => (elem && elem.note ? elem.note : elem))
    };

    submittedOrder = normalizeOrderDates({
      ..._pickBy(submittedOrder, x => x !== '' && x !== null),
      diagnosis: _pickBy(submittedOrder.diagnosis, x => x !== '' && x !== null),
      patient: _pickBy(submittedOrder.patient, x => x !== '' && x !== null)
    });

    dispatch(disableForm());

    try {
      if (state.submitType === SubmitTypes.test) {
        const { body } = await apiClient.post('orders/test/', { params: submittedOrder });
        dispatch({ type: OrderActions.ORDER_TEST_SUCCESS });
        showToast({ type: 'success', message: 'Order is valid' });
      } else {
        const { body } = await apiClient.post('orders/', {
          params: {
            ...submittedOrder,
            system_owner: 'brightree'
          }
        });
        dispatch(push('/'));
        dispatch(submitSuccess(body, draft.id));
      }
    } catch ({ status, body = { error: 'Bad Request' }, header, ...rest }) {
      if (
        status === 401 &&
        header.hasOwnProperty('user-change') &&
        header['user-change'] === 'true'
      ) {
        window.location.replace('/exogen-direct/app');
      } else {
        const err = body && body.error ? body.error : 'Error submitting order';
        dispatch(submitFail(err, body));
      }
    }
  };
};

export function toggleAdvocacyModal(
  currentOrderId = null,
  currentSapId = null,
  orderLocation = null
) {
  return {
    type: OrderActions.ORDER_ADVOCACY_TOGGLE_MODAL,
    currentOrderId,
    currentSapId,
    orderLocation
  };
}

const { PATIENT_ADVOCACY, PATIENT_ADVOCACY_SUCCESS, PATIENT_ADVOCACY_FAIL } = OrderActions;

export function forwardPatientAdvocacy({ orderId, orderLocation, referralTypes, note }) {
  return (dispatch, getState) => {
    dispatch({
      types: [PATIENT_ADVOCACY, PATIENT_ADVOCACY_SUCCESS, PATIENT_ADVOCACY_FAIL],
      orderId,
      orderLocation,
      patient_advocacy_initiated: moment().toString(),
      promise: client =>
        client.post(`orders/${orderId}/advocacy/`, {
          params: {
            referralTypes,
            note
          }
        })
    });
  };
}

export function toggleCancelOrderModal(show) {
  return {
    type: OrderActions.ORDER_CANCEL_MODAL_TOGGLE,
    show
  };
}

export function toggleSubmissionModal() {
  return {
    type: OrderActions.ORDER_SUBMISSION_TOGGLE_MODAL
  };
}

export function setSubmitType(submitType = SubmitTypes.brightreeSubmit) {
  return {
    type: OrderActions.SET_SUBMIT_TYPE,
    submitType
  };
}

export function deleteIfClean(id) {
  return {
    type: OrderActions.DRAFT_DELETE,
    id,
    currentIfClean: true
  };
}

export function deleteDraft(id) {
  return async (dispatch, getState) => {
    const drafts = getState().orders.drafts.items;
    const draft = drafts.find(dr => dr.client_id === id);

    if (draft.id) {
      if (isOnline(getState)) {
        try {
          const result = await apiClient.delete(`drafts/${draft.id}/`);
          dispatch({ type: OrderActions.DRAFT_DELETE, id });
        } catch ({ body = { error: 'Bad Request' } }) {
          const message =
            body && body.error
              ? `Error deleting draft on server: ${body.error}`
              : 'Error deleting draft on server';
          showToast({ type: 'error', message });
        }
      } else {
        showToast({ type: 'warn', message: 'You are offline. Please try deleting later.' });
      }
    } else {
      dispatch({ type: OrderActions.DRAFT_DELETE, id });
    }
  };
}

export function toggleOrderHistoryModal(show) {
  return {
    type: OrderActions.TOGGLE_ORDER_HISTORY_MODAL,
    show
  };
}

export function getDuplicatePatientInfo({
  first_name = null,
  middle_initial = null,
  last_name = null,
  date_of_birth = null,
  date_of_birth_draft = null,
  client_id = null
}) {
  return dispatch => {
    dispatch({ type: OrderActions.GET_DUPLICATE_PATIENT_INFO });
    apiClient
      .get(`duplicate-patient-info/`, {
        params: {
          first_name: first_name,
          middle_initial: middle_initial,
          last_name: last_name,
          date_of_birth: date_of_birth,
          date_of_birth_draft: date_of_birth_draft,
          client_id: client_id
        }
      })
      .then(response => {
        const result = response;

        dispatch({ type: OrderActions.GET_DUPLICATE_PATIENT_INFO_SUCCESS, result });

        if (response?.body?.data?.territories?.length > 0) {
          // no toast is shown if the duplicate patient is found but no associated orders/drafts
          const arrTerritories = response?.body?.data?.territories.map(
            val => `${val.brightree_sales_order_id}: ${val.territory__code}`
          );
          if (arrTerritories.length > 0)
            showToast({
              type: 'warning',
              message: `Duplicate patient detected for BT order(s) ${String(arrTerritories)}`,
              autoClose: false
            });
        } else if (response?.body?.data?.draft_creators?.length > 0) {
          const arrDraftUsers = response?.body?.data?.draft_creators.map(
            val => `${val.created_by?.first_name} ${val.created_by?.last_name}`
          );
          if (arrDraftUsers.length > 0)
            showToast({
              type: 'warning',
              message: `Duplicate patient detected for draft(s) created by ${String(
                arrDraftUsers
              )}`,
              autoClose: false
            });
        }
      })
      .catch(error => dispatch({ type: OrderActions.GET_DUPLICATE_PATIENT_INFO_FAIL, error }));
  };
}

export function sendPatientEmail(orderId, email, isDraft, language) {
  return async dispatch => {
    try {
      let draftResponse = { body: [] };
      if (isDraft) draftResponse = await apiClient.get(`drafts/${orderId}/`, { params: { returnDict: true } })

      if (isDraft && Object.keys(draftResponse?.body).length === 0) {
        showToast({
          type: 'warning',
          message: 'Please save draft before sending email'
        });
      } else {
        dispatch({ type: OrderActions.SEND_PATIENT_EMAIL, orderId })
        const res = await apiClient.post(`orders/send_patient_email/`, {
          params: { orderId, email, isDraft, language }
        })
        if (isDraft) {
          dispatch({
            type: OrderActions.SEND_PATIENT_EMAIL_DRAFT_SUCCESS,
            result: res?.body?.data,
            draft_version: res?.body?.draft_version
          });
        } else {
          dispatch({
            type: OrderActions.SEND_PATIENT_EMAIL_ORDER_SUCCESS,
            result: res?.body?.data
          });
        }

        showToast({
          type: 'info',
          message: 'Email sent to patient.'
        });
      }
    } catch (response) {
      const errorMessage = response.body?.error || 'Error sending patient email';
      dispatch({ type: OrderActions.SEND_PATIENT_EMAIL_FAIL, orderId, error: errorMessage });
      showToast({
        type: 'error',
        message: errorMessage
      });
    }

  }
}


export function saveAndSendPatientWelcomeEmail(orderId, email, isDraft, formValues, language) {
  return async (dispatch, getState) => {
    let ordersState = getState().orders;
    const userState = getState().user;

    if (!ordersState.currentOrder) {
      alert("Can't save, returning to dashboard.");
      dispatch(push('/'));
      return;
    }

    dispatch({
      type: OrderActions.ORDER_SAVE_CHANGES,
      formValues
    });

    showToast({ type: 'info', message: 'Order saved...' });

    ordersState = getState().orders;

    const updatedDraft = ordersState.currentOrder;

    if (!updatedDraft) {
      alert("Can't find draft, returning to dashboard.");
      dispatch(push('/'));
      return;
    }

    if (!String(updatedDraft.order.id).startsWith('tmp')) {
      alert('Invalid draft id.  Returning to dashboard.');
      dispatch(push('/'));
      return;
    }

    if (updatedDraft.order.id !== formValues.id || updatedDraft.client_id !== formValues.id) {
      captureForSentry({ updatedDraft, formValues }, 'Draft id does not match form id');
      alert('Invalid draft form.  Returning to dashboard');
      dispatch(push('/'));
      return;
    }

    if (updatedDraft.order.sap_id || formValues.sap_id) {
      captureForSentry({ updatedDraft, formValues }, 'Draft id does not match form id');
      alert('Invalid draft form.  Returning to dashboard');
      dispatch(push('/'));
      return;
    }

    const promise =
      updatedDraft && updatedDraft.hasOwnProperty('id')
        ? apiClient.put(`drafts/${updatedDraft.id}/`, { params: updatedDraft })
        : apiClient.post('drafts/', { params: updatedDraft });

    try {
      const response = await promise;
      dispatch({
        type: OrderActions.DRAFT_SUBMIT_SUCCESS,
        draft: response.body.draft
      });
      try {
        dispatch({ type: OrderActions.SEND_PATIENT_EMAIL, orderId })
        const res = await apiClient.post(`orders/send_patient_email/`, {
          params: { orderId, email, isDraft, language }
        })
        if (isDraft) {
          dispatch({
            type: OrderActions.SEND_PATIENT_EMAIL_DRAFT_SUCCESS,
            result: res?.body?.data,
            draft_version: res?.body?.draft_version
          });
        } else {
          dispatch({
            type: OrderActions.SEND_PATIENT_EMAIL_ORDER_SUCCESS,
            result: res?.body?.data
          });
        }

        showToast({
          type: 'info',
          message: 'Email sent to patient.'
        });

      } catch (response) {
        const errorMessage = response.body?.error || 'Error sending patient email';
        dispatch({ type: OrderActions.SEND_PATIENT_EMAIL_FAIL, orderId, error: errorMessage });
        showToast({
          type: 'error',
          message: errorMessage
        });
      }
    } catch ({ status, body = {}, header }) {
      if (status === 404) {
        showToast({
          type: 'error',
          message: 'Draft was not found.  Deleting and redirecting to dashboard.'
        });
        dispatch({ type: OrderActions.DRAFT_DELETE, id: updatedDraft.client_id });
        dispatch(push('/'));
      }
      if (
        status === 401 &&
        header.hasOwnProperty('user-change') &&
        header['user-change'] === 'true'
      ) {
        window.location.replace('/exogen-direct/app');
      } else {
        const err = body && body.error ? body.error : null;
        const message = err
          ? `Error saving draft to server: ${err}`
          : 'Error saving draft to server';

        showToast({ type: 'error', message });

        if (err === 'Draft is outdated.  Please download changes.') {
          dispatch(getDraft(updatedDraft.id));
        }
      }
    }
  };
}
